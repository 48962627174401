
import { Component, Vue, Ref } from "vue-property-decorator";
import { toRaw } from "vue";
import axios, { AxiosError } from "axios";
import { Printer } from "@/models/Printer";
import { Store } from "@/models/Store";
import SearchComponent from '@/components/SearchComponent.vue';
import { NotificationOptions } from "@/util/NotificationOptionsPresets"

@Component({
    name: "LabelPrintersPage",
    components: {
        SearchComponent
    }
})
export default class LabelPrintersPage extends Vue {

    mounted() {
        this.loadPrinters();
    }

    protected loadingPrinters = true;
    protected printers: Printer[] = [];
    protected nameFilter = "";
    protected selectedPrinter: Printer | null = null;
    protected showEditModal = false;
    @Ref('printerTable') protected printerTable : any;

    private fields = [
        { key: "ipAddress", label: "IP Address", sortable: true },
        { key: "name", sortable: true },
        { key: "store.name", label: "Store", sortable: true },
        { key: "description", sortable: true },
        { key: "actions", label: "" }
    ];

    get filteredPrinters() {
        return this.printers.filter(p => p.name.toLowerCase().includes(this.nameFilter));
    }

    async loadPrinters() {
        try {
            this.loadingPrinters = true;
            const response = await axios.get<Printer[]>('/Printer');
            this.printers = response.data;
        } catch (error) {
            console.warn(error);
        } finally {
            this.loadingPrinters = false;
        }
    }

    addPrinter() {
        this.selectedPrinter = new Printer();
        this.showEditModal = true;
    }

    editPrinter(event : any) {
        if (!event.length) return;
        // @ts-ignore
        this.selectedPrinter = structuredClone(toRaw(event[0]));
        this.showEditModal = true;
    }

    handleModalHide() {
        this.printerTable.clearSelected();
        this.selectedPrinter = null;
    }

    storeOptionLabel(store: Store) {
        if (store.id != undefined)
            return `${store.id} - ${store.name}`;
        return;
    }

    async savePrinter(event : any) {
        event.preventDefault();
        try {
            const response = await axios.post("/Printer", this.selectedPrinter);
            this.printers = response.data;
            this.showEditModal = false;
        } catch (error) {
            const errorMessage = (error as AxiosError)?.response?.data ?? "There was an error saving the printer";
            this.$notification(NotificationOptions.error(errorMessage));
        }
    }

    async deletePrinter(printer: Printer) {
        const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this printer?', {
            title: 'Confirm Delete Printer',
            okVariant: 'danger',
            centered: true,
        });

        if (!confirm) return;

        try {
            const response = await axios.delete(`/Printer/${printer.id}`);
            this.printers = response.data;
        } catch (error) {
            const errorMessage = (error as AxiosError)?.response?.data ?? "There was an error deleting the printer";
            this.$notification(NotificationOptions.error(errorMessage));
        }
    }

    get isAdmin(): boolean {
        return this.$user?.isAdmin;
    }
}

